.tinjauan-Wrapper{
    color: #00828F;
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    font-size: larger;
}
.tinjauan-Wrapper h1{
    margin-top: 0px;
    text-align: start;
    padding-top: 4%;
}
.tinjauanProfile-Wrapper{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.tinjauanImage-Wrapper{
    border: dashed 2px #00828F;
    padding: 2px;
}
.tinjauan-form-Wrapper{
    margin-left: 5%;
}
.doubleTinjauan{
    margin-bottom: 4%;
    display: flex;
    flex-direction: row;
    text-align: start;
    color: #818181;
}
.tinjauan-Wrapper{
    text-align: start;
    color: #818181;
}
.singleTinjauan input{
    width: 375px;
    height: 1px;
    margin-bottom: 2%;    
}
.singleTinjauan {
    margin-top: 2%;
}
.tinjauan-Wrapper input{
    background-color: #F9F9F9;
    margin-right: 50px;
    padding: 4%;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.ubahTinjauan{
    display: flex;
    flex-direction: row;
    justify-content: end; 
    align-items: center;
    margin-top: 10%;
}
.ubahTinjauan button{
    font-size: large;
    background: #FFBE1A;
    border-radius: 5px;
    border: none;
    color: white;
    width: 20%;
    margin-right: 20px;
    cursor: pointer;
}
.TinjauanButton-Wrapper{
    text-align: end;
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
    margin-top: 5%;
    margin-right: 4%;
}
.TinjauanButton-Wrapper h1{
    font-weight: 900;
    font-size: larger;
    color: #F54545;
    margin-right: 4%;
    cursor: pointer;
}
.TinjauanButton-Wrapper button{
    width: 20%;
    margin-bottom: 5%;
    font-size: large;
    font-weight: 600;
    color: #F9F9F9;
    border: none;
    height: 59px;
    background: #F9B401;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    cursor: pointer;
}
.tinjauan-Wrapper table, th, td{
    width: 800px;
    text-align: center;
    border-collapse: collapse;
    border: 1px solid black;
}
.tableContainer{
    margin-top: 4%;
    padding: 1%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: min-content;
}
.judulJadwal{
    display: flex;
    flex-direction:row;
    color: #00828F;
}
.judulJadwal1{
    margin-right: 0.5%;
    font-weight: bolder;
}
.errorTinjauan{
    color: white;
    background-color: red;
    margin-bottom: 1%;
    text-align: center;
    width: 100%;
    padding: 1%;
}