.mainPage2{
    /* height: 100vh; */
    background-color: #81C0C5;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  .tittle{
      margin-top: 0;
      padding-top: 1%;
  }
  
  .boxLeft{
    background-color: white;
    height: 95%;
    width: 40%;
    border-radius: 2% 0% 0% 2%; 
  }
  
  .boxLeft img{
    width: 9%;
    margin-left: 2%;
    margin-right: 2%;
  }
  
  .boxLeft p{
    margin: 0%;
    font-size: larger;
  }
  
  .logoWrapper{
    margin-top: 11%;
  }
  
  .boxLeft input{
    width: 75%;
    margin-top: 5%;
    height: 50px;
    background: #F9F9F9;
    border: white;
    box-shadow: 0px 4px 4px rgba(172, 172, 172, 0.25);
    border-radius: 1px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .inputWrapper{
    display:block
  }
  
  .boxRight{
    background-color: #00828F;
    height: 95%;
    width: 40%;
    border-radius: 0% 2% 2% 0%;
    display: flex;
    align-items: center;
  }
  .boxRight2{
    background-color: #9ff1f8;
    height: 95%;
    width: 40%;
    border-radius: 0% 2% 2% 0%;
    display: flex;
    align-items: center;
  }
  
  .boxRight img{
    width: 75%;
  }
  
  .forget{
    color: #F9B401;
    margin-left: 50%;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  
  .boxLeft button{
    width: 50%;
    margin-top: 5%;
    font-size: xx-large;
    font-weight: 600;
    color: #F9F9F9;
    border: none;
    height: 59px;
    background: #F9B401;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
  }

  .login-alert{
    color: red;
    text-align: start;
    margin-left: 14%;
    padding-top: 1%;
  }
  .ErrorAlert{
    background-color: red;
    color: white;
    font-weight: 800;
    padding: 1%;
  }

  .privacy{
    text-align: justify;
    padding-top: 0;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 5%;
  }

  .privacy2{
    text-align: justify;
    padding-top: 0;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 1%;
  }
  /* <FontAwesomeIcon icon={faSpinner} spin/> */