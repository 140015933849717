.thanksWrapper h1{
    text-align: center;
    color: #818181;
    margin-top: 0px;
    margin-bottom: 1%;
    font-size: 150%;
}
.thanksWrapper{
    font-size: larger;
    padding-top: 7%;
    padding-left: 15%;
    padding-right: 15%;
    color: #818181;
}
.thanksDetail{
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: start;
}
.thanksWrapper p{
    margin-top: 10%;
}
.thanksWrapper button{
    margin-top: 10%;
    font-size: larger;
    color: white;
    background-color: #61FF97;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px; 
    width: 30%;
}