.judulJadwal-Wrapper{
    display: flex;
    justify-content: start;
    margin-left: 7%;
}
.Jadwal-Wrapper button{
    width: 20%;
    margin-bottom: 5%;
    font-size: large;
    font-weight: 600;
    color: #F9F9F9;
    border: none;
    height: 59px;
    background: #F9B401;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}
.Jadwal-Wrapper input{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0.5%;
    border: none;
    font-size: large;
    width: 80%;
}
.Jadwal-Wrapper select{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0.5%;
    border: none;
    font-size: large;
    width: 40%;
}

.judulJadwal-Wrapper h1{
    color: #00828F;
    margin-bottom: 0px;
}
.judulJadwal-Bold{
    margin-right: 0.5%;
}
.judulJadwal-Thin{
    font-weight:normal;
}
.jadwalCard-Wrapper{
    display: flex;
    flex-direction: column;
    background-color: #81C0C5;
    margin: 3%;
    margin-top: 1%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    text-align: start;
    padding:3%;
    padding-top: 0.5%;
    font-size:medium;
    font-weight: 700;
    color: white;
}
.jadwalCard-Wrapper2{

    display: flex;
    flex-direction: column;
    background-color: #5b6b6d;
    margin: 3%;
    margin-top: 1%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    text-align: start;
    padding:3%;
    padding-top: 0.5%;
    font-size:medium;
    font-weight: 700;
    color: white;
}
.jadwal-DoubleInput {
    display: flex;
    flex-direction: row;
    font-size: large;
}
.jadwal-DoubleInput input{
    width: 120%;
    margin-right: 70PX;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0.5%;
    border: none;
    font-size: large;
    width: 155px;
    padding: 5%;
}
.jadwalKelas{
    margin-left: 2px;
}
.jadwalKelas input{
    width: 500px;
    padding: 2%;
}
.jadwal-DoubleInput select{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0.5%;
    border: none;
    font-size: large;
    
    padding: 2%;
    width: 400px;
    margin-right: 50px;
}
.tambahJadwal {
    color: #00828F;
    text-align: end;
    font-weight: 900;
    margin-right: 4%;
    font-size: larger;
}
.jadwalButton-Wrapper{
    text-align: end;
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
    margin-top: 5%;
    margin-right: 4%;
}
.jadwalButton-Wrapper h1{
    font-weight: 900;
    font-size: larger;
    color: #F54545;
    margin-right: 4%;
}
.delete{
    display: flex;
    justify-content: end;
    margin-bottom: 0px;
    margin-top: 1%;
    margin-right: 21%;
}
.delete button {
    cursor: pointer;
    margin-bottom: 0%;
    background-color: #F54545;
    width: 9%;
}
.tabelJadwal{
    margin-left: 2%;
}
.tabelJadwal button {
    cursor: pointer;
    margin-bottom: 0%;
    background-color: #F54545;
    width: 50%;
}