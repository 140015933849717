.detailTeacher-Wrapper{
    padding : 2%;
}
.detailTeacher-Wrapper Table, tr, td {
    border-style: none;
    text-align: start;
}
.detailTeacher-Wrapper tr:nth-child(odd) 
{background-color: #f2f2f2;}

.detailTeacher-Wrapper h1{
    text-align: start;
    color: #00828F;
}
.tabelJadwal th{
    background-color: #81C0C5;
    border-style: none;
    padding: 2px;
    color: white;
}
.tabelJadwal {
    text-align: center;
}
.detailTeacher-Wrapper button{
    width: 15%;
    margin-top: 2%;
    margin-bottom: 5%;
    font-size: x-large;
    font-weight: 600;
    color: #F9F9F9;
    border: none;
    height: 40px;
    background: #F9B401;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}