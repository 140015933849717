.Judul-Wrapper{
    display: flex;
    justify-content: start;
    margin-left: 7%;
}

.Biodata-wrapper button{
    width: 20%;
    margin-bottom: 5%;
    font-size: large;
    font-weight: 600;
    color: #F9F9F9;
    border: none;
    height: 59px;
    background: #F9B401;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.Judul-Wrapper h1{
    color: #00828F;
    margin-bottom: 0px;
}
.Judul-Bold{
    margin-right: 0.5%;
}
.Judul-Thin{
    font-weight: normal;
}

.biodataFormWrapper{
    display: flex;
    flex-direction: column;
    background-color: #81C0C5;
    margin: 3%;
    margin-top: 1%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    text-align: start;
    padding:3%;
    padding-top: 0.5%;
    font-size:medium;
    font-weight: 700;
    color: white;
}
.biodataFormWrapper input{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0.5%;
    border: none;
    font-size: large;
    width: 80%;
}
.doubleInput select{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0.5%;
    border: none;
    font-size: large;
    width: 30%;
}
.doubleInput {
    display: flex;
    flex-direction: row;
    font-size: large;
    width: 100%;
}
.doubleInput input{
    width: 180%;
    padding: 4%;
}
.doubleInput select{
    padding: 2%;
    width: 400px;
    margin-right: 50px;
}
.biodataFormWrapper p{
    margin-bottom: 0.5%;
    font-size: larger;
}
.alamat-Wrapper {
    padding-left: 1%;
    font-weight: 500;
}
.alamat-Wrapper select{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0.5%;
    border: none;
    font-size: large;
    width: 80%;
}
.tripleInput select{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0.5%;
    border: none;
    font-size: large;
    width: 30%;
}
.tripleInput {
    display: flex;
    flex-direction: row;
    font-size: large;
    width: 100%;
}
.tripleInput input{
    width: 150px;
    padding: 3%;
    margin-right: 50px;
}
.tripleInput select{
    padding: 2%;
    width: 400px;
    margin-right: 50px;
}
.doubleInput2 {
    display: flex;
    flex-direction: row;
    font-size: large;
    width: 100%;
}
.doubleInput2 input{
    width: 400px;
    padding: 1%;
    margin-right: 50px;
}
.dapodik-Radio{
    font-weight: 600;
    margin-top: 3%;
    font-size: x-large;
    display: flex;
    justify-content: space-around;
}
.dapodikText{
    width: 900px;
}
.dapodik-Radio input{
    box-shadow: none;
    margin: 0px;
    padding: 0px;
}
.Biodata-Error{
    background-color:red;
    font-size:larger;
    font-weight:bold;
    color:white;
    padding: 1%;
}