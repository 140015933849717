.navBar-Wrapper{
    background-color: #00828F;
    position:fixed;
    width: 20%;
    height: 100vh;
    padding-top: 2%;
    display: flex;
    flex-direction: column;
    text-align: start;
    padding-left: 1%;
}
.navBar-Menu Link{
    color: white;
}


.judul {
    justify-content: left;
    align-items: center;
    color: white;
    font-size: 75%;
    display: flex;
    flex-wrap: wrap;
    text-align: justify;
}
.penutup {
    justify-content: left;
    padding-top: 10%;
    padding-left: 18%;
    align-items: center;
    color: white;
    font-size: 40%;
    font-weight: lighter;
    display: flex;
    flex-wrap: wrap;
    text-align: justify;
}
.navBar-Menu{
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    color: white;
    padding-left: 7%;
}
.navBar-Menu p{
    color: white;
    margin-bottom: 0px;
}
.judul h1{
    margin: 0;
}
.judul img{
    width: 24%;
    margin-right: 4%;
}
.menu-Row{
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    background-color: none;
    padding: 3%;
    border-radius: 11px;
    width: 85%;
    text-decoration: none;
    color: white;
}
.menu-Row img{
    margin-right: 7%;
}
.menuChild{
    padding-left: 9%;
}

.linkActive{
    background-color: #81C0C5;
}
.navBar-Menu2{
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    color: white;
    padding-left: 7%;
}