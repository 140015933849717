.tinjauData-Wrapper table{
    margin: 1%;
    border-style: none;
}
.tinjauData-Wrapper th{
    font-size: normal;
    color:white;
    border-style: none;
    background :#81C0C5;
    padding: 0.1%;
}
.tinjauData-Wrapper tr{
    border-style: none;
}
.tinjauData-Wrapper tr:nth-child(even) 
{background-color: #f2f2f2;}
.tinjauData-Wrapper td{
    border-style: none;
}
.tableContainer{
    width:99%;
    padding:1%;
    margin-top: 0px;
}
.tinjuanButtonAlign{
    width: 400%;
    display: flex;
    flex-direction: column;
    justify-content:right;
}
.tinjuanButtonAlign2{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content:right;
}
.tinjuanButtonAlign button{
    width: 4%;
    padding: 4px;
    margin: 1px;
    margin-top: 4px;
    border-radius: 3px;
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}
.lihat{
    background-color: #00828F;
}
.hapus{
    background-color:#F54545;
}
.tinjauData-Header{
    margin-left: 2%;
    padding-top: 3%;
    text-align:start;
    font-size: large;
}
.judulTinjauan{
    color:#00828F;
    font-weight: bolder;
}
.namaSekolah{
    color:#818181;
    font-size: xx-large;
    font-weight:bolder;
}

.Judul-Wrapper{
    display: flex;
    justify-content: start;
    margin-left: 7%;
}

.Biodata-wrapper button{
    width: 10%;
    margin-left: 88%;
    margin-top: 1%;
    margin-bottom: 0%;
    font-size: medium;
    font-weight: 400;
    color: #F9F9F9;
    border: none;
    height: 30px;
    background: #a3a3a3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.Judul-Wrapper h1{
    color: #00828F;
    margin-bottom: 0px;
}
.Judul-Bold{
    margin-right: 0.5%;
}
.Judul-Thin{
    font-weight: normal;
}
.doubleInput {
    display: flex;
    flex-direction: row;
    font-size: large;
    width: 100%;
} 
