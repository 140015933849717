.registerOperatorWrapper{
    text-align: center;
    color: #00828F; 
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.registerJudul{
    padding-top: 3%;
    font-size: 300%;
    font-weight: bolder;
    letter-spacing: -2px;
}
.subJudul{
    padding-top: 1%;
}
.registerSubJudul{
    font-size: 150%;
}
.boxForm{
    display: flex;
    flex-direction: column;
    background-color: #00828F;
    margin: auto;
    margin-top: 1%;
    padding:2%;
    width: 35%;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: start;
    border-radius: 10px;
}
.boxForm label{
    color: white;
    font-weight: bold;
    margin-bottom: 1%;
    margin-top: 2%;
}
.boxForm select{
    height: 30PX;
    border: none;
    border-radius: 5px;
}
.boxForm button{
    font-size: larger;
    font-weight: bolder;
    width: 25%;
    margin: auto;
    margin-top: 2%;
    padding: 1%;
    color: white;
    background-color: #FFBE1A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
}
.boxForm input{
    height: 30PX;
    border: none;
    border-radius: 5px;
}
.registerAlert{
    padding-top: 1px;
    color: red;
}
.modalAlign{
    padding-top: 10%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
}
.modalJudul{
    color: #00828F;
    font-size: xx-large;
    font-weight: bolder;
    
}
.modalInput{
    margin: auto;
    margin-top: 2%;
    height: 30PX;
    border-radius: 5px;
    width: 40%;
}
.buttonAlign{
    display: flex;
}
.modalButton{
    font-size: larger;
    font-weight: bolder;
    width: 25%;
    margin: auto;
    margin-top: 2%;
    padding: 1%;
    color: white;
    background-color: #FFBE1A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
}
.registerAlert{
    font-size: large;
    padding: 1%;
    font-weight: bold;
}