.profilWrapper{
    padding:4%;
    font-size:large;
}
.profilJudul{
    color: #818181;
    font-weight: bolder;
    font-size: xx-large;
    text-align: start;
    margin-bottom: 3%;
}
.rowProfile{
    display: flex;
    flex-direction: row;
    text-align: start;
    border-bottom: 3px solid #C4C4C4;
    padding-bottom: 1%;
    margin-top:1%;
}
.columnProfile{
 margin-right: 20%;   
}
.itemProfile{
    padding-top: 1px;
}
.detailProfileHeader{
    color: #00828F;
    font-weight: bolder;
    padding-bottom: 1%;
    
}
.detailProfileBody{
    border-bottom: 3px solid #E6E6E6;
    padding-bottom: 1%;
}

.buttonAlign{
    display: flex;
}
.modalButton{
    font-size: larger;
    font-weight: bolder;
    width: 25%;
    margin: auto;
    margin-top: 2%;
    padding: 1%;
    color: white;
    background-color: #FFBE1A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
}
.modalAlign input {
    margin: auto;
    margin-top: 2%;
    height: 30PX;
    border-radius: 5px;
    width: 40%;
}
.modalAlign{

}