.InputData{
    height: 100vh;
}
.InputData h1{
    margin-top: 0%;
    padding-top: 5%;
    color: #818181;
    margin-bottom: 3%;
}
.InputData h2{
    margin-top: 6%;
    color: #818181;
    font-weight: 400;
}
.cardWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.card{
    margin-left: 2%;
    margin-right: 2%;
    width: 18%;
    height: 500px;
    background-color: #81C0C5;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.iconWrapper{
    height: 65%;
    display: flex;
    padding: auto;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
}

.iconWrapper img{
    width: 65%;
}

.textWrapper h1{
    margin: 0%;
}
.textWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    height: 35%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 10px 10px;
}