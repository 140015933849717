.inputGuru-Wrapper{
    padding-top: 4%;
}

.inputGuru-Wrapper select{
    background: #F9F9F9;
    width: 25%;
    font-size: larger;
    color: #A1A1A1;
    padding: 1%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    border: none;
    margin-bottom: 3%;
}
.inputGuru-Wrapper h1 {
    color: #81C0C5;
}

.radioWrapper{
    display: flex;
    flex-wrap: wrap;
    font-size: large;
    font-weight: 600;
    color: #818181;
    align-items: center;
}

.radioWrapper img{
    width: 200px;
}

.iconContainer {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.iconContainer input{
    cursor: pointer;
}
.inputGuru-Wrapper button{
    background-color: #FFBE1A;
    font-size: larger;
    font-weight: 600;
    color: white;
    border: none;
    padding: 1%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
}