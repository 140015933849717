.tinjauData-Wrapper table{
    margin: 1%;
    border-style: none;
}
.tinjauData-Wrapper th{
    font-size: normal;
    color:white;
    border-style: none;
    background :#81C0C5;
    padding: 0.1%;
}
.tinjauData-Wrapper tr{
    border-style: none;
}
.tinjauData-Wrapper tr:nth-child(even) 
{background-color: #f2f2f2;}
.tinjauData-Wrapper td{
    border-style: none;
}
.tableContainer{
    width:99%;
    padding:1%;
    margin-top: 0px;
}
.tinjuanButtonAlign{
    width: 400%;
    display: flex;
    flex-direction: column;
    justify-content:center;
}
.tinjuanButtonAlign button{
    width: 4%;
    padding: 4px;
    margin: 1px;
    margin-top: 4px;
    border-radius: 3px;
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}
.lihat{
    background-color: #00828F;
}
.hapus{
    background-color:#F54545;
}
.tinjauData-Header{
    margin-left: 2%;
    padding-top: 3%;
    text-align:start;
    font-size: large;
}
.judulTinjauan{
    color:#00828F;
    font-weight: bolder;
}
.namaSekolah{
    color:#818181;
    font-size: xx-large;
    font-weight:bolder;
}
